<template>
  <section class="hero">
    <div class="hero-video">
      <video
        ref="show"
        muted
        playsinline
        :src="vsrc"
        id="show"
      ></video>
    </div>
    <div class="hero-body">
      <div class="container center">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="content is-large has-text-white">
              <div class="intro has-text-justified" id="first">
                <span
                  >Hello and welcome to my about page, you're either here
                  because you want to learn more about me
                  <strong class="has-text-white">OR</strong> you just want to
                  hide this obnoxious text and enjoy the show either way, ya
                  best start scrolling</span
                >
              </div>
              <div class="second block" id="second">
                <span
                  >So I guess you're looking to know more about me? Well for
                  starters, I highly dislike writing, in fact, I failed English
                  throughout <a href="https://www.artanddesignhs.org/">high school</a>, and was almost left-back because of it
                </span>
              </div>
              <div class="third block" id="third">
                <span
                  >Speaking of High School, that's where my
                  <strong class="has-text-white">systemic-education</strong>
                  stops. Just like this video ;]
                </span>
              </div>
              <div class="fourth block">
                <span
                  >Ok slight lie, I did in the most demotivating way ever.. sign
                  up for a semester jam-packed of remedial   courses. Looking back
                  on it, there were several variables that led me there but the
                  biggest one that comes to mind is financing, if you know from
                  the beginning of your highschool career that you're at best
                  going to be able to afford a semester in community college or
                  have to take a loan from <strike>Tony Sorprano</strike> Sallie
                  Mae.. you kinda just lose the motivation to care or try.
                </span>
              </div>
              <div class="fifth block">
                <span>
                  Alright alright, the goal here isn't to write a book, actually
                  I'm not even sure what my goal is.. because at the end of the
                  day.. I'm really just looking to try new stuff out here, with
                  that said I'll briefly go over my career for those interested,
                  or if you're just looking to get in touch you can skip to the
                  bottom
                </span>
              </div>
              <div class="sixth block">
                <span>
                  At this current moment, I work for an Editorial company within
                  NYC, I have been there for three years now, and have won
                  <strong class="has-text-white">two</strong> awards for my
                  work. My first award was for my role in moving the company to
                  it's new office,
                  <strong class="has-text-white"
                    >Me and my small team of two
                  </strong>
                  took on all of the IT & Infrastrucutre work that is involved
                  with moving a 500+ headcount office.
                </span>
              </div>
              <div class="seventh block">
                <span>
                  My second award was for a product that I co-developed with two
                  other folks. It's a data product that helps to keep track of
                  key metrics from our various social media accounts written in
                  javascript & a slightly less known language FORK, it utilizes
                  <a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com/docs/firestore">Firestore</a>,<a target="_blank" rel="noopener noreferrer" href="https://cloud.google.com/bigquery">BigQuery</a
                  >,<a target="_blank" rel="noopener noreferrer" href="https://support.google.com/datastudio/answer/6283323?hl=en">Data-Studio</a>
                </span>
              </div>
              <div class="eight block">
                <span>
                  Prior to working there, I worked for two post-production
                  facilities, who just happen to be on opposites sides of the
                  media spectrum.
                  <a target="_blank" rel="noopener noreferrer" href="https://finalframepost.com/">Final Frame</a> is a
                  a facility that specializes in coloring films, secondarily to
                  that they also rent out space to larger productions, and by
                  renting that space they also, in turn rented me, As I was the
                  one-man IT team at Final Frame at the time. I can honestly say
                  that even though I was working there three years ago, I'm
                  still waiting for my current job to catch up to the technology
                  they were using.
                </span>
              </div>
              <div class="ninth block">
                <span>
                  Prior to working at Final Frame, I spent 4 years working at
                  <a href="https://www.syncroservices.com/" class="">Syncro Services</a> Syncro Services is a post-production facility that
                  handles advertising media. I started as an intern laying down
                  captioning for
                  <strong class="has-text-white">
                    American Express, Chase, Hardees, IBM.. </strong
                  >just to name a few. I was converted from intern to fulltime
                  within my first year there, once I was full-time my
                  responsibilities grew to delivering commercials through playing
                  out to tape, but also through automation. Syncro is by far my
                  favorite point of my career thus far, you know how everyone
                  loves the 'No Frills' Pizza Shop Experience? Take that same
                  atmosphere but throw a room full of digi-beta decks, and mac
                  pro towers. The men there helped shaped me into the
                  proffesional that I am today.
                </span>
              </div>
              <div class="tenth block" id="contact" ref="contact">
                <div class="container">
                  <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/nyke-harewood">
                    <b-icon
                      type="is-white"
                      icon="linkedin"
                      size="is-small"
                      class="mr-4"
                    ></b-icon>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://github.com/mynameisnyke">
                    <b-icon
                      type="is-white"
                      icon="github"
                      size="is-small"
                      class="mr-4"
                    ></b-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "IntroShow",
  components: {},
  data() {
    return {
      playlists: [],
      darkPlaylists: [],
      device: "",
      welcomeLabels: null,
      label: "hello",
      typedKey: 0,
      vid: {},
      windowHeight: window.innerHeight,
      vsrc: 'https://storage.googleapis.com/nl-vbgs/C0016-720p.mp4'
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      console.log(`it changed to ${newHeight} from ${oldHeight}`);
    },
  },
  created: async function () {
    this.$root.$on("lightSwitched", (e) => {
      this.changeLight(e);
    });

    // Lets check for mobile and set an appproriate video 
    if (this.$device.mobile) this.vsrc ='https://storage.googleapis.com/nl-vbgs/C0015-720p.mp4'

  },
  mounted: async function () {
    // Declare Scene
    const scene2 = this.$scrollmagic
      .scene({
        // ID of element where animation starts
        triggerElement: "#first",
        triggerHook: 0,
      })
      .on("enter", () => {
        this.$refs.show.play();
      });

    const scene3 = this.$scrollmagic
      .scene({
        // ID of element where animation starts
        triggerElement: "#third",

        // {0,0.5,1} - animations starts from {top,center,end} of window
        triggerHook: 0.5,

        // Duration of animation
        duration: 300,
      })
      .on("enter", () => {
        this.$refs.show.pause();
      })
      .on("leave", () => {
        this.$refs.show.play();
      });

    // Add Scene to controller
    this.$scrollmagic.addScene(scene2);
    this.$scrollmagic.addScene(scene3);
  },
};
</script>




<style >
@media screen and (max-width: 768px) {
  .hero-video {
    display: block;
  }
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero-video video {
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  object-fit: cover;
  display: block;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
}
div.block {
  margin-top: 100vh;
  margin-bottom: 50vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
}
.intro {
  margin-top: 50vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

.center:-webkit-scrollbar {
  display: none;
}
</style>
