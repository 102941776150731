<template>
  <div>
    <Vi />
    <About />
  </div>
</template>

<script>
import Vi from "./Vi";
import About from "./About";

export default {
  name: "Home",
  components: {
    Vi,
    About,
  },
  data() {
    return {};
  },
  mounted: async function () {},
  methods: {
  },
};
</script>

<style>

</style>
