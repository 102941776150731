<template>
  <div id="app">
    <Nav />
    <router-view></router-view>
  </div>
</template>

<script>
import firebase from "./firebasebooter.js";
import Nav from "./components/layout/Navbar";


firebase.analytics();
export default {
  name: "App",
  components: {
    Nav
  },
  data() {
    return {};
  },
  mounted: async function () {},
  methods: {},
};
</script>

<style>
body.has-navbar-fixed-top {
    padding-top: 0
}
html.has-navbar-fixed-top {
    padding-top: 0
}

</style>
