<template>
  <section class="hero" :style="{ height: windowHeight + 'px', 'margin-top': -3.25 + 'rem' }">
    <div class="hero-video">
      <video ref="welcomeVideo" autoplay muted loop playsinline></video>
    </div>
    <div class="hero-body">
      <h1 class="sub">NYKE LAB</h1>
      <div class="hacker-text">
        <vue-typed-js
          :key="typedKey"
          :cursorChar="'_'"
          :typeSpeed="200"
          v-if="welcomeLabels"
          :strings="welcomeLabels"
        >
          <h1 class="typing"></h1>
        </vue-typed-js>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "../firebasebooter.js";

const db = firebase.firestore();

export default {
  name: "Vi",
  components: {},
  data() {
    return {
      playlists: [],
      darkPlaylists: [],
      device: "",
      welcomeLabels: null,
      label: "hello",
      typedKey: 0,
      vid: {},
      windowHeight: window.innerHeight,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     console.log(`it changed to ${newHeight} from ${oldHeight}`);
    }
  },
  created: async function () {
    this.$root.$on("lightSwitched", (e) => {
      this.changeLight(e);
    });
  },
  mounted: async function () {
    // Make a call out to firebase to get playlist objects
    await this.fetchPlayist();
    // Check device type
    this.device = this.$device;

    // Set's the playlist for mobile vieweing experience
    if (this.device.mobile) {
      this.playlists = this.playlists.filter(
        (obj) => !obj.desktop && !obj.dark
      );
      this.darkPlaylists = this.darkPlaylists.filter(
        (obj) => !obj.desktop && obj.dark
      );
      console.log(
        "hello is see you're on a mobile device, so setting a vertical video to play"
      );
      await this.fetchRandomVideo();
      return;
    }

    if (this.$browserDetect.isSafari) {
      this.playlists = this.playlists.filter((obj) => {
        return obj.mp4 && obj.labels
      })

      this.darkPlaylists = this.darkPlaylists.filter((obj) => {
        return obj.mp4 && obj.labels
      })
    }
    // Filter out vertical videos for dekstop experience
    this.playlists = this.playlists.filter((obj) => {
      return obj.desktop && obj.labels;
    });
    this.darkPlaylists = this.darkPlaylists.filter((obj) => {
      return obj.desktop && obj.labels;
    });
    await this.fetchRandomVideo();
  },
  methods: {
    changeLight: async function (sw) {
      console.log(sw);
      switch (sw) {
        case "dark":
          console.log(sw);
          await this.fetchRandomVideo(this.darkPlaylists);
          console.log(this.vid)
          this.typedKey++;
          break;
        case "light":
          await this.fetchRandomVideo(this.playlists);
          console.log(this.vid)
          this.typedKey++;
          break;
        default:
          break;
      }
      console.log(sw);
    },
    fetchPlayist: async function () {
      this.playlists = [];
      await db
        .collection("playlists")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.dark) {
              this.darkPlaylists.push(data);
              return;
            }
            this.playlists.push(data);
          });
        })
        .catch((error) => {
          console.log("Error getting playist documents: ", error);
        });
    },
    fetchRandomVideo: async function (pl) {


      // If playlist was supplied will pull from that, else pull from defaul playlist Array
      if (pl) {
        console.log(
          pl[0],
          "fist item of passeed playist to fetchrandomvideo function"
        );
        console.log("Receieved a playist with my random video request");
        let vidObj = pl[Math.floor(Math.random() * pl.length)];
        this.vid = vidObj;
        this.welcomeLabels = vidObj.labels;
        console.log(this.vid)
        console.log(this.device)

        // Check to see if on desktop and not safari, if so will send webm for better quality.
        if (!this.device.mobile && vidObj.webm && !this.$browserDetect.isSafari) {
          console.log("Playing a webm video", vidObj.webm);
          this.$refs.welcomeVideo.src = vidObj.webm;
          this.$refs.welcomeVideo.play();
          return;
        }

        this.$refs.welcomeVideo.src = vidObj.mp4;
        this.$refs.welcomeVideo.play();
        return vidObj;
      }

      // Fetch random video
      let vidObj = this.playlists[
        Math.floor(Math.random() * this.playlists.length)
      ];

      // Save the video and its labels to local dataset
      this.vid = vidObj;
      this.welcomeLabels = vidObj.labels;

      // Checks to see if we should set a webm for dekstop experience or mp4 for mobile.
      if (!this.device.mobile && vidObj.webm && !this.$browserDetect.isSafari) {
        console.log("Playing a webm video", vidObj.webm);
        this.$refs.welcomeVideo.src = vidObj.webm;
        this.$refs.welcomeVideo.play();
        return;
      }

      console.log("Playing a mp4 video", vidObj.mp4);
      this.$refs.welcomeVideo.src = vidObj.mp4;
      this.$refs.welcomeVideo.play();
      return;
    },
  },
};
</script>

<style >
@media screen and (max-width: 768px) {
  .hero-video {
    display: block;
  }
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero-video video {
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  object-fit: cover;
  display: block;
}
h1.sub {
  font-weight: 500;
  font-size: 4em;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 30px;
  margin-bottom: 17px;
}
div.hacker-text {
  font-size: 2em;
  color: white;
  clear: both;
  position: absolute;
  bottom: 35px;
  left: 32px;
  margin: 0;
}
</style>
