import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import device from "vue-device-detector"
import { VueTypedJs } from 'vue-typed-js'
import VueRouter from 'vue-router'
import Home from './components/Home'
import IntroShow from './components/IntroShow'
import ScrollAnimation from './directives/scrollAnimation'
import VueScrollmagic from 'vue-scrollmagic'
import browserDetect from "vue-browser-detect-plugin";
 

// Add your plugins here
Vue.use(Buefy)
Vue.use(device)
Vue.use(VueRouter)
Vue.component('vue-typed-js', VueTypedJs)
Vue.use(Vuex)
Vue.use(VueScrollmagic)
Vue.use(browserDetect);



// Directives 

Vue.directive('scrollanimation', ScrollAnimation)

// Routes
const routes = [
  { path: '/', component: Home },
  { path: '/', hash: 'about' },
  { path: '/About', component: IntroShow },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

const store = new Vuex.Store({
  state: {
    dark: false,
    hideNavSwitch: false
  },
  mutations: {
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
