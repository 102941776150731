<template>
  <b-navbar transparent>
    <template slot="brand">
      <b-navbar-item>
        <div class="field">
          <b-switch
            @input="lightSwitcher"
            size="is-small"
            false-value="light"
            true-value="dark"
            v-model="isSwitched"
            native-value="off"
          ></b-switch>
        </div>
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item
        class=""
        tag="router-link"
        :to="{ path: '/', component: 'Home' }"
      >
        Home
      </b-navbar-item>
      <b-navbar-item
        class=""
        tag="router-link"
        :to="{ path: '/About', component: 'IntroShow' }"
      >
        About
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "Nav",
  props: ["vi"],
  methods: {
    lightSwitcher() {
      this.$root.$emit("lightSwitched", this.isSwitched);
    },
  },
  data() {
    return {
      isSwitched: false,
      sw: false,
    };
  },
};
</script>

<style scoped>
.navbar {
  background-color: transparent;
  background: transparent;
}
.lightOn {
  color: white;
}
.lightOff {
  color: white;
}

.navbar-item {
  color: white;
}
</style>