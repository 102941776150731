<template>
  <section class="section pt-5" :style="{ backgroundColor: bgc }">
    <div class="container has-text-left has-text-weight-light">
      <div class="columns is-0">
        <div class="column is-half">
          <div class="img-container">
            <transition appear name="fade">
              <img :key="headerImgKey" :src="headerImg" />
            </transition>
          </div>
        </div>
        <div class="column is-half content">
          <p
            v-scrollanimation
            class="bio is-size-7-mobile is-size-5"
            :style="{ color: txtc }"
          >
            NYKE LAB is a digital workbench created by
            <strong :style="{ color: txtc }">Nyke Harewood</strong>, The video
            component above was my first attempt at mixing back end and front
            end programming. All videos are shot by me, but all of the labels
            are created through
            <a
              href="https://youtu.be/rHY3T7-vK38"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="is-family-code">object detection</span></a
            >
            or as sales folks say <strong :style="{ color: txtc }">A I</strong>,
            on top of that through analyzing the metadata of my videos, this
            site serves different content depending on what type of device
            you're on. To help conserve bandwidth, I experimented with various
            codecs and settled on webm for desktop, and mp4 for mobile...
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://news.ycombinator.com/item?id=16319651"
            >
              <span class="has-text-small"> thanks Apple</span></a
            >
            :], but webm's take long and I'd rather google fit the bill for
            electricity, thus I phoned
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/mynameisnyke/hoenheim"
            >
              Hoenheim.</a
            >
            <i>.... if you know you know</i>. I plan to add a detailed writeup
            about that soon, perhaps with some cool data visualizations.
            <span v-if="bgc === 'white'">
              Lastly, if you haven't already flipped it yet, the switch located
              in the top left will enable the crowd favorite
              <strong :style="{ color: txtc }">Dark Mode</strong>
            </span>
            <span v-if="bgc === 'black'"
              >So you already figured out dark mode, you can continue toggiling
              it back and forth to switch the video and layout
            </span>
          </p>
          <div class="blotter">
            <vue-blotter
              family="Inconsolata"
              :fill="txtc"
              text-style="normal"
              :size="30"
              material-type="LiquidDistortMaterial"
              text="Nyke Harewood"
              :uniforms="{
                uSpeed: 0.10,
              }"
              :key="blotterKey"
            >
              <h1
                slot-scope="{ blotterScope }"
                @mousemove="(e) => mouseMove(e, blotterScope)"
              ></h1>
            </vue-blotter>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueBlotter from "vue-blotter";

export default {
  name: "About",
  components: {
    VueBlotter
  },
  data: function () {
    return {
      bgc: "white",
      txtc: "#2E2E2E",
      scrolledToBottom: false,
      headerImg: "https://storage.googleapis.com/nl-pics/childhood.jpg",
      headerImgKey: 0,
      blotterKey: 0
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.scrolledToBottom = true;
          this.headerImg =
            "https://storage.googleapis.com/nl-pics/adulthood.jpg";
        }
      }
    },
    mouseMove(e, scope) {
          scope.material.uniforms.uSpeed.value = (e.x + e.y) / 4500
      }
  },
  created: function () {
    // Listen to dark mode switch and handle
    this.$root.$on("lightSwitched", (e) => {
      console.log(e);
      switch (e) {
        case "light":
          this.txtc = "#2E2E2E";
          this.bgc = "white";
          this.blotterKey++
          break;
        case "dark":
          this.bgc = "black";
          this.txtc = "#FFFFFF";
          this.blotterKey++
          break;
        default:
          break;
      }
    });
  },
  mounted: async function () {
    this.scroll();
  },
};
</script>

<style scoped>
img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.before-enter {
  opacity: 0;
  /* transform: translateY(100px); */
  transition: all 2s ease-out;
}
.enter {
  opacity: 1;
  transform: translateY(0px);
  transition: all 1s ease-in;
}
</style>