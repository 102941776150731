import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDEE-gqEYrwAGF2Man69dSKxfZY7oyRGDU",
    authDomain: "nykelab.firebaseapp.com",
    databaseURL: "https://nykelab.firebaseio.com",
    projectId: "nykelab",
    storageBucket: "nykelab.appspot.com",
    messagingSenderId: "447919988422",
    appId: "1:447919988422:web:a285afa2bb6fb4ef6341c7",
    measurementId: "G-SYMHF9HPYB"
  };

// Initialize & Export Firebase
export default firebase.initializeApp(firebaseConfig);